body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.brand-font, .navbar-brand {
    font-family: Garamond, serif;
}

.btn-outline-light {
    margin: 20px;
}

.bg-image {
    background-position: top;
    background-repeat: no-repeat;
}

a.active {
    font-weight: bold;
}